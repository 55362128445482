import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import de from "../../languaje/de";
import es from "../../languaje/es";
import { useAppState } from "../../hooks/useAppState";
import en from "../../languaje/en";


const images = ["v1731116016/f8qrdtnzoiidxcr73jjh.webp"];

const QuienesSomos = () => {
    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const handleClickHome = () => {
        navigate("/");
    };

    const handleClickSeleccionReclutamiento = (path)=>{
        navigate(path);
      }

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
    };

    const optimizedImageURL = (url) => {
        const cloudinaryBaseURL = 'https://res.cloudinary.com/dj1rac5ah/image/upload/';
        return `${cloudinaryBaseURL}c_scale,w_2000/${url}`;
    };

    const [data,setData] = useState(es.QuienesSomos);
    const {tipoIdioma} = useAppState();

    useEffect(()=>{
        if(tipoIdioma==='de'){
            setData(de.QuienesSomos);
        }else if(tipoIdioma==='en'){
            setData(en.QuienesSomos);
        }else{
            setData(es.QuienesSomos);
        }
    },[tipoIdioma]);

    return (
        <>
            <div className="w-full h-screen bg-bg_favorite_1 relative">
                <img
                    src={optimizedImageURL(images[currentImageIndex])}
                    alt="NOT FOUND"
                    className="absolute top-0 left-0 w-full h-full object-cover z-0"
                />
                
                <div className="bg-bg_favorite_1 flex flex-col justify-center items-center md:items-end h-full z-20 relative space-y-4 p-4 pt-32 sm:pt-48 md:pt-64 sm:p-6 md:p-8">
                    <div className="w-full md:w-3/5 h-auto">
                        <h2 className="text-white font-bold text-xl sm:text-4xl md:text-5xl font-montserrat text-center md:text-end px-2">
                        {data.info1.title}
                        </h2>
                    </div>
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-10">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`w-3 h-3 rounded-full ${index === currentImageIndex ? 'bg-white' : 'bg-gray-400'} cursor-pointer`}
                            onClick={() => handleImageClick(index)}
                        ></div>
                    ))}
                </div>
                </div>
            </div>
            <div className="w-11/12 px-2 sm:px-10 pb-10 mx-auto">
                <p className="text-base text-gray-400 py-10 font-montserrat">
                    <span onClick={handleClickHome} className="hover:cursor-pointer text-gray-800 font-medium">
                        Innova <span className="text-gray-500 font-medium text-base">Corp</span>
                    </span>
                    {' > '}
                    {data.info1.title}
                </p>
                <div className="text-gray-700 text-start w-full">
                    <h2 className="">{data.info1.description}</h2>
                </div>
                <div className="py-5">
                    <h2 className="text-lg font-montserrat font-bold text-gray-800 text-start">{data.mission.title}</h2>
                    <p className="text-gray-700 text-start">{data.mission.description}</p>
                </div>
                <div className="py-5">
                    <h2 className="text-lg font-montserrat font-bold text-gray-800 text-start">{data.vision.title}</h2>
                    <p className="text-gray-700 text-start ">{data.vision.description}</p>
                </div>
                <div className="py-5">
                    <h2 className="text-3xl font-montserrat font-bold text-gray-800 text-center">{data.valueTitle}</h2>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8 px-0 ">
                        {data.values.map((value, index) => (
                            <div key={index} className="text-start">
                                <h3 className="text-xl font-semibold text-gray-800">{value.title}</h3>
                                <p className="text-sm text-gray-600 mt-2">{value.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-full bg-white py-10">
                    <h2 className="text-3xl font-bold font-montserrat text-gray-800 text-center">{data.team.title}</h2>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 px-6 sm:px-10 lg:px-20">
  {data.team.members.map((member, index) => (
    <div
      key={index}
      className="bg-gray-100 shadow-lg rounded-lg p-6 text-center transform transition duration-300 hover:scale-105 hover:shadow-2xl"
    >
      <div className="w-36 h-36 mx-auto rounded-full overflow-hidden mb-4">
        <img
          src={optimizedImageURL(member.image)}
          alt={`${member.name} photo`}
          className={`w-full h-full ${index===0 ? 'object-contain' : 'object-cover'} object-center ${index !== 0 ? 'scale-125' : ''}`}
        />
      </div>
      <h3 className="text-xl font-semibold text-gray-800">{member.name}</h3>
      <p className="text-sm text-gray-600 mt-2">{member.description}</p>
    </div>
  ))}
</div>

                </div>
                {/* AGREGAR AQUI */}
                <div className="py-5">
                    <h2 className="text-lg font-montserrat font-bold text-gray-800 text-start">{data.porqueelegirnos.title}</h2>
                    <p className="text-gray-700 text-start ">{data.porqueelegirnos.descripcion1}</p>
                </div>
                    
            </div>
            <div className="w-full max-h-96 bg-gray-800 flex justify-center items-center py-20">
                <p className="w-11/12 font-montserrat m-auto text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-white text-center">
                {data.porqueelegirnos.descripcion2}
                </p>
            </div>
            <div className="w-full bg-gray-200 flex flex-col sm:flex-row flex-nowrap">
                <div className="w-full flex justify-center items-center">
                    <div className="w-full text-center p-4">
                        <h2 className="font-semibold text-gray-800 my-2 text-2xl md:text-3xl">{data.homeInfo2}</h2>
                        <button
                        className="border-gray-800 border-2 px-4 py-2 sm:px-6 sm:py-3 font-medium bg-gray-800 text-white hover:bg-gray-200 hover:text-gray-800 hover:border-gray-500 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50"
                        onClick={()=>handleClickSeleccionReclutamiento(data.homeInfo3.path)}
                        >
                        {data.homeInfo3.label}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuienesSomos;
