import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {FaFacebookF, FaTwitter, FaLinkedinIn, FaAngleRight} from 'react-icons/fa'
import { useAppState } from "../../hooks/useAppState";
import de from "../../languaje/de";
import es from "../../languaje/es";
import en from "../../languaje/en";

const Footer = () => {
    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const optimizedImageURL = (url) => {
        const cloudinaryBaseURL = 'https://res.cloudinary.com/dj1rac5ah/image/upload/';
        return `${cloudinaryBaseURL}c_scale,w_800/${url}`;
    };

    const [activeInformationen,setActiveInformationen] = useState(false);
    const [meineDienstleistungen,setMeineDienstleistungen] = useState(false);
    const [kontakt,setKontakt] = useState(false);
    const [socialMedia,setSocialMedia] = useState(false);

    const { tipoIdioma } = useAppState();
  const [homeData,setHomeData] = useState(es.footer);

    useEffect(()=>{
        if(tipoIdioma==='de'){
          setHomeData(de.footer);
        }
        else if(tipoIdioma==='en'){
            setHomeData(en.footer);
        }
        else{
          setHomeData(es.footer);
        }
    },[tipoIdioma]);

    return (
        <footer className="bg-gray-700 text-white py-8">
            <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 ">
                <div className="text-center mb-8">
                    <div className="flex items-center justify-center ">
                        <Link to="/" onClick={handleLinkClick} className='flex flex-col items-center'>
                            <img src={optimizedImageURL('v1731102767/lcohm3bg6zbea6ukxnil.png')} alt="Logo Principal" className='h-24' loading='lazy' />
                        </Link>
                    </div>
                </div>
                
                <div className="md:flex  md:flex-wrap md:justify-between md:items-start px-0 w-full">
                    <div className="mb-6 w-full md:w-auto bg-white text-gray-700 md:bg-transparent md:text-white px-2 py-1">
                        <div className="w-full flex justify-between items-center" onClick={()=>setActiveInformationen(!activeInformationen)}>
                            <h2 className="text-lg font-semibold py-1">{homeData.Informationen.title}</h2>
                            <FaAngleRight className="hover:cursor-pointer md:hidden"/>
                        </div>
                        <ul className={`${activeInformationen ? '' :'hidden'} px-2 md:block py-2`}>
                            {homeData.Informationen.links.map((link) => {
                                return (
                                    <li className="mb-2" key={link.link}>
                                        <Link to={link.link} className="font-normal">{link.detail}</Link>
                                    </li>
                                );
                            })}
                        </ul>

                    </div>
                    <div className="mb-6 w-full md:w-auto bg-white text-gray-700 md:bg-transparent md:text-white px-2 py-1">
                        <div className="w-full flex justify-between items-center" onClick={()=>setMeineDienstleistungen(!meineDienstleistungen)}>
                            <h2 className="text-lg font-semibold py-1">{homeData.MeineDienstleistungen.title} </h2>
                            <FaAngleRight className="hover:cursor-pointer md:hidden"/>
                        </div>
                        <ul className={`${meineDienstleistungen ? '' :'hidden'} px-2 md:block py-2`}>
                            {homeData.MeineDienstleistungen.links.map((link)=>{ 
                                return (
                                    <li className="mb-2" key={link.link}><Link to={link.link} className="font-normal">{link.detail}</Link></li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="mb-6 w-full md:w-auto bg-white text-gray-700 md:bg-transparent md:text-white px-2 py-1">
                        <div className="w-full flex justify-between items-center" onClick={()=>setKontakt(!kontakt)}>
                            <h2 className="text-lg font-semibold py-1">{homeData.Kontakt.title}</h2>
                            <FaAngleRight className="hover:cursor-pointer md:hidden"/>
                        </div>
                        <ul className={`${kontakt ? '' :'hidden'} px-2 md:block py-2`}>
                            {homeData.Kontakt.links.map((link)=>{ 
                                return (
                                    <li className="mb-2" key={link.detail}>{link.detail}</li>
                                )
                            })}
                        </ul>
                    </div>
                    {/* <div className="mb-6 w-full md:w-auto bg-white text-gray-700 md:bg-transparent md:text-white px-2 py-1">
                        <div className="w-full flex justify-between items-center" onClick={() => setSocialMedia(!socialMedia)}>
                            <h2 className="text-lg font-semibold py-1">{homeData.SocialMedia.title}</h2>
                            <FaAngleRight className="hover:cursor-pointer md:hidden" />
                        </div>
                        <ul className={`${socialMedia ? '' : 'hidden'} px-2 md:block py-2`}>
                            {homeData.SocialMedia.links.map((link) => {
                                let Icon;
                                switch (link.detail) {
                                    case 'Facebook':
                                        Icon = FaFacebookF;
                                        break;
                                    case 'Twitter':
                                        Icon = FaTwitter;
                                        break;
                                    case 'LinkedIn':
                                        Icon = FaLinkedinIn;
                                        break;
                                    default:
                                        Icon = null;
                                }

                                return (
                                    <li className="mb-2 flex items-center" key={link.detail}>
                                        {Icon && <Icon className="mr-2 text-xl" />}
                                        <a
                                            href={link.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="font-normal hover:underline"
                                        >
                                            {link.detail}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div> */}
                </div>
                <hr className="my-8 border-white" />
                <div className="text-center">
                    <p className="font-medium">&copy; {homeData.info}</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
