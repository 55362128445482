import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  TextField,
  MenuItem,
  Select,
  Input,
  FormControl,
  InputLabel,
  styled
} from '@mui/material';
// import { styled } from '@mui/system';
import { CloudUpload, Delete } from '@mui/icons-material';
import de from '../../languaje/de';
import { useAppState } from '../../hooks/useAppState';
import es from '../../languaje/es';
import en from '../../languaje/en';
import { Button } from 'react-scroll';

const Contacto = () => {
  const navigate = useNavigate();
  const [formType, setFormType] = useState('firma');
  const [formData, setFormData] = useState({
    nombre: '',
    telefono: '',
    correo: '',
    mensaje: '',
    servicio: '',
  });
  const [formDataServicio,setFormDataServicio] = useState("");

  const [alertMessage, setAlertMessage] = useState(null);
const [alertType, setAlertType] = useState('success'); // 'success' or 'error'

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
        ...prevData,
        [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailData = {
      nombre: formData.nombre,
      correo: formData.correo,
      telefono: formData.telefono,
      mensaje: formData.mensaje,
      servicio: formData.servicio
    };
    emailjs.send('service_vh753sg', 'template_gcqkar9', emailData, 'DrNbhXgHzsbP2s2Z0')
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          alert('Mensaje enviado correctamente');
        },
        (error) => {
          console.error('Error al enviar el correo:', error.text);
          alert('Hubo un error al enviar el mensaje.');
        }
      );
  };
  

  const handleClickHome = () => {
    navigate("/");
};

const optimizedImageURL = (url) => {
    const cloudinaryBaseURL = 'https://res.cloudinary.com/dievolijo/image/upload/';
    return `${cloudinaryBaseURL}c_scale,w_2000/${url}`;
};



const [contactInfo,setContactInfo] = useState(es.Contactanos);
const {tipoIdioma} = useAppState();

useEffect(()=>{
    if(tipoIdioma==='de'){
      setContactInfo(de.Contactanos);
    }else if(tipoIdioma==='en'){
      setContactInfo(en.Contactanos);
    }else{
      setContactInfo(es.Contactanos);
    }
},[tipoIdioma]);


  return (
    <>
      <div className="w-full h-screen bg-bg_favorite_1 relative">
        <img 
        src={optimizedImageURL("v1720048164/r5phhfvrwr57yofnqlsu.jpg")} 
        alt="NOT FOUND" 
        className="absolute top-0 left-0 w-full h-full object-cover z-0" 
        />
        <div className="bg-bg_favorite_1 flex flex-col justify-center items-center md:items-end h-full z-20 relative space-y-4 p-4 pt-32 sm:pt-48 md:pt-64 sm:p-6 md:p-8">
          <div className="w-full md:w-1/2 h-auto">
              <h2 className="text-white font-bold text-2xl sm:text-4xl  md:text-5xl font-montserrat text-center md:text-end px-2">
                {contactInfo.title} 
              </h2>
          </div>
        </div>
      </div>
      <div className="container mx-auto w-11/12 md:w-3/5 pb-10">
        <p className="text-base text-gray-400 py-10">
            <span onClick={handleClickHome} className="hover:cursor-pointer text-gray-800 font-medium">
                Innova <span className="text-gray-500 font-medium text-base">Corp</span>
            </span>
            {' > '}
            {contactInfo.title}
        </p>
        <div className="text-gray-700 text-start w-full">
            <p className=""> {contactInfo.description}</p>
        </div>
        <div className="w-full max-lg:mx-w--auto bg-white shadow-xl rounded px-4 md:px-8 pt-6 pb-8 my-4 ">
            <form onSubmit={handleSubmit} className="grid grid-cols-2  gap-4">
              <div className="w-full col-span-2 sm:col-span-1">
              <TextField
                label={contactInfo.nombre}
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
                fullWidth
                InputProps={{
                    style: {
                        fontFamily: 'Arial, sans-serif',
                        fontSize: '1rem',
                    },
                }}
                InputLabelProps={{
                    style: {
                        fontFamily: 'Arial, sans-serif',
                        fontSize: '1rem',
                    },
                }}
              />
              </div>
              <div className="w-full col-span-2 sm:col-span-1">
                    <TextField
                      label={contactInfo.correo}
                      name="correo"
                      value={formData.correo}
                      onChange={handleChange}
                      required
                      fullWidth
                      InputProps={{
                        style: {
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '1rem',
                        },
                      }}
                      InputLabelProps={{
                          style: {
                              fontFamily: 'Arial, sans-serif',
                              fontSize: '1rem',
                          },
                      }}
                    />
                </div>
                <div className="w-full col-span-2 sm:col-span-1">
                    <TextField
                      label={contactInfo.telefono}
                      name="telefono"
                      value={formData.telefono}
                      onChange={handleChange}
                      required
                      fullWidth
                      InputProps={{
                        style: {
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '1rem',
                        },
                        }}
                        InputLabelProps={{
                            style: {
                                fontFamily: 'Arial, sans-serif',
                                fontSize: '1rem',
                            },
                        }}
                    />
                </div>
                <div className="w-full col-span-2 sm:col-span-1">
                  <FormControl fullWidth>
                      <InputLabel
                          id="servicio-label"
                          sx={{
                              fontFamily: 'Arial, sans-serif',
                              fontSize: '1rem',
                          }}
                      >
                          Selecciona un servicio
                      </InputLabel>
                      <Select
                          label={contactInfo.servicio}
                          name="servicio"
                          value={formData.servicio}
                          onChange={handleChange}
                          labelId="servicio-label"
                          required
                          fullWidth
                          sx={{
                              fontFamily: 'Arial, sans-serif',
                              fontSize: '1rem',
                          }}
                      >
                          <MenuItem value={contactInfo.servicios.opcion1} sx={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}>
                            {contactInfo.servicios.opcion1}
                          </MenuItem>
                          <MenuItem value={contactInfo.servicios.opcion2} sx={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}>
                            {contactInfo.servicios.opcion2}
                          </MenuItem>
                          <MenuItem value={contactInfo.servicios.opcion3} sx={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}>
                            {contactInfo.servicios.opcion3}
                          </MenuItem>
                          <MenuItem value={contactInfo.servicios.opcion4} sx={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}>
                            {contactInfo.servicios.opcion4}
                          </MenuItem>
                      </Select>
                  </FormControl>
                </div>
                <div className="col-span-2">
                    <TextField
                      label={contactInfo.mensaje.descripcion}
                      id="mensaje"
                      name="mensaje"
                      value={formData.mensaje}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined" 
                      InputProps={{
                        style: {
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '1rem',
                        },
                      }}
                      InputLabelProps={{
                          style: {
                              fontFamily: 'Arial, sans-serif',
                              fontSize: '1rem',
                          },
                      }}
                    />
                </div>
                <div className="col-span-2 flex justify-center items-center">
                    <button
                        type="submit"
                        className="w-full md:w-1/3 py-2 bg-gray-800 text-white font-bold rounded transition duration-200 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50"
                    >
                        {contactInfo.enviar}
                    </button>
                </div>

          </form>
        </div>

      </div>
      {alertMessage && (
        <div className={`alert ${alertType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'} p-4 mb-4 rounded`}>
            {alertMessage}
        </div>
        )}

    </>
  );
};

export default Contacto;


  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     // Aquí puedes agregar la lógica para enviar los datos del formulario
  //     console.log("Servicio seleccionado:", selectedService);
  //     console.log("Preferencia de contacto:", contactPreference);
  // };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const formData = new FormData();
  
//     Object.keys(formValues).forEach(key => {
//       formData.append(key, formValues[key]);
//     });
  
//     fetch('http://localhost:3001/backend/kontakt/send-email', {
//       method: 'POST',
//       body: formData,
//     })
//     .then(response => response.json())
//     .then(data => {
//       setAlertMessage('Email sent successfully!');
//       setAlertType('success');
//     })
//     .catch((error) => {
//       setAlertMessage('Failed to send email.');
//       setAlertType('error');
//     });
// };
