import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppState } from "../../hooks/useAppState";
import de from "../../languaje/de";
import es from "../../languaje/es";
import en from "../../languaje/en";
import { AiOutlineClose } from "react-icons/ai";
import { Check, FactCheck } from "@mui/icons-material";




const DesarrolloSoftware = () => {
    const images = ["v1731472053/z8uhjmfzjhc7zx1kouwq.png"];

    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickHome = () => {
        navigate("/");
    };

    const handleClickSeleccionReclutamiento = (path)=>{
        navigate(path);
      }


    const optimizedImageURL = (url) => {
        const cloudinaryBaseURL = 'https://res.cloudinary.com/dj1rac5ah/image/upload/';
        return `${cloudinaryBaseURL}c_scale,w_2000/${url}`;
    };

    const [data,setData] = useState(de.NuestroServicio);
    const {tipoIdioma} = useAppState();

    useEffect(()=>{
        if(tipoIdioma==='de'){
            setData(de.NuestroServicio);
        }else if(tipoIdioma==='en'){
            setData(en.NuestroServicio);
        }else{
            setData(es.NuestroServicio);
        }
    },[tipoIdioma]);

    const [showModal, setShowModal] = useState(false);
    const [currentModalImage, setCurrentModalImage] = useState("");

      // Function to open the modal with the selected image
      const handleImageClick = (image) => {
        setCurrentModalImage(image);
        setShowModal(true);
    };
    const closeModal = () => setShowModal(false);

    return (
        <>
            <div className="w-full h-screen bg-bg_favorite_1 relative">
                <img
                    src={optimizedImageURL(images[currentImageIndex])}
                    alt="NOT FOUND"
                    className="absolute top-0 left-0 w-full h-full object-cover z-0"
                    loading="lazy"
                />
                
                <div className="bg-bg_favorite_1 flex flex-col justify-center items-center md:items-end h-full z-20 relative space-y-4 p-4 pt-32 sm:pt-48 md:pt-64 sm:p-6 md:p-8">
                    <div className="w-full md:w-3/5 h-auto">
                        <h2 className="text-white font-bold text-xl sm:text-4xl md:text-5xl font-montserrat text-center md:text-end px-2">
                        {data.DesarrolloSoftware.title}
                        </h2>
                    </div>
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-10">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`w-3 h-3 rounded-full ${index === currentImageIndex ? 'bg-white' : 'bg-gray-400'} cursor-pointer`}
                            onClick={() => handleImageClick(index)}
                        ></div>
                    ))}
                </div>
                </div>
            </div>
            <div className="w-11/12 px-2 sm:px-10 pb-10 mx-auto">
                <p className="text-base text-gray-400 py-10 font-montserrat">
                    <span onClick={handleClickHome} className="hover:cursor-pointer text-gray-800 font-medium">
                        Innova <span className="text-gray-500 font-medium text-base">Corp</span>
                    </span>
                    {' > '}
                    {data.DesarrolloSoftware.title}
                </p>
                <div className="text-gray-700 text-start w-full mb-2">
                    <p className=""> {data.DesarrolloSoftware.description}</p>
                </div>
                {/* <h3 className="text-xl font-semibold text-gray-800 pb-5 pt-5">{data.DesarrolloSoftware.productosSeguritechTitle}</h3> */}
                {/*  */}
                
                {/*  */}
                {/* <div className="text-gray-700 text-start w-full">
                    <p className=""> {data.DesarrolloSoftware?.description2}</p>
                </div> */}
            </div>
            <div className="w-full bg-gray-100 py-10">
                    <div className="w-11/12 mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
                    {data.DesarrolloSoftware.productosSeguritech?.map(
                        (product, index) => {

                        return (
                            <div
                            key={index}
                            className="bg-white p-6 shadow-md rounded-lg transform transition-transform hover:scale-105 relative overflow-hidden"
                            >
                            <div
                                className="overflow-hidden rounded-md mb-4 relative group"
                                style={{ height: "14rem" }}
                            >
                                <img
                                src={optimizedImageURL(product.image)}
                                alt={product.image}
                                className="w-full h-full object-contain cursor-pointer"
                                onClick={() =>
                                    handleImageClick(optimizedImageURL(product.image))
                                }
                                />
                            </div>
                            <p className={`${index === 0 ? 'text-base' : 'text-sm'} text-gray-600`}>
        {product.description}
      </p>
                                <h3 className="text-gray-700 py-2 font-bold text-sm">{product?.subTittle}</h3>
                                {product?.options?.map((p, index) => (
                                    <div key={index} className="flex items-center text-gray-600 text-sm">
                                    <Check className="mr-2" /> {/* Ícono con margen a la derecha */}
                                    <p>{p}</p>
                                    </div>
                                ))}
                            </div>
                        );
                        }
                    )}
                    </div>
                </div>
            <div className="w-full bg-gray-200 flex flex-col sm:flex-row flex-nowrap">
                <div className="w-full flex justify-center items-center">
                <div className="w-full text-center p-4">
                    <h2 className="font-semibold text-gray-800 my-2 text-2xl md:text-3xl">{data.homeInfo2}</h2>
                    <button
                        className="border-gray-800 border-2 px-4 py-2 sm:px-6 sm:py-3 font-medium bg-gray-800 text-white hover:bg-gray-200 hover:text-gray-800 hover:border-gray-500 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50"
                        onClick={()=>handleClickSeleccionReclutamiento(data.homeInfo3.path)}
                        >
                        {data.homeInfo3.label}
                    </button>
                </div>
                </div>
            </div>
            {/* Modal for Image Display */}
            {showModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
                    <div className="bg-white p-4 rounded shadow-lg">
                    <button
                        className="absolute top-2 right-2 border-gray-800 rounded-full px-2 py-2 sm:px-2 sm:py-2 font-medium bg-gray-800 text-white hover:bg-gray-200 hover:text-gray-800 hover:border-gray-500 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50"
                        onClick={closeModal}
                    >
                        <AiOutlineClose size={16} />
                    </button>
                        <img
                        src={currentModalImage}
                        alt="Modal"
                        className="w-full h-auto object-contain"
                        />
                    </div>
                    </div>
                )}
        </>
    );
};

export default DesarrolloSoftware;
