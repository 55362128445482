import React, { useEffect, useState } from 'react';
import { useAppState } from '../../hooks/useAppState';
import de from '../../languaje/de';
import en from '../../languaje/en';
import es from '../../languaje/es';

const DerechoUsuario = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { tipoIdioma } = useAppState();
    const [data, setData] = useState(es.DerechoUsuario);

    useEffect(() => {
        if (tipoIdioma === 'de') {
            setData(de.DerechoUsuario);
        } else if (tipoIdioma === 'en') {
            setData(en.DerechoUsuario);
        } else {
            setData(es.DerechoUsuario);
        }
    }, [tipoIdioma]);

    return (
        <div className="p-8 bg-white text-gray-900 mt-32 max-w-4xl mx-auto ">
            <h1 className="text-3xl font-extrabold mb-6 text-center text-violet-900 font-bell">{data.title}</h1>
            <p className="mb-6 text-lg leading-relaxed">{data.description1}</p>
            
            <section className="mb-6">
                <address className="text-lg leading-relaxed">
                    <strong>{data.infos.info1}</strong> {data.companyName}<br />
                    <strong>{data.infos.info2}</strong> {data.companyAddress}<br />
                    <strong>{data.infos.info3}</strong> <a href={`mailto:${data.email}`} className="text-blue-600">{data.email}</a><br />
                    {/* <strong>{data.infos.info4}</strong> <a href={`tel:${data.phone}`} className="text-blue-600">{data.phone}</a> */}
                </address>
            </section>
            
            <p className="mb-6 text-lg leading-relaxed">{data.description2}</p>

            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                {/* Lista de temas */}
                {data.topics.map((topic, index) => (
                    <div key={index} className="mb-4">
                        <h3 className="text-xl font-semibold mb-2 text-gray-800">{topic.title}</h3>
                        <p className="mb-4 text-lg leading-relaxed">{topic.description}</p>
                    </div>
                ))}
                
                {/* Información de contacto adicional */}
                {/* <section className="mt-6">
                    <h3 className="text-xl font-semibold mb-2 text-gray-800">{data.additionalContactInfo.title}</h3>
                    <address className="text-lg leading-relaxed"> */}
                        {/* <strong>{data.additionalContactInfo.companyLabel}</strong> {data.additionalContactInfo.companyName}<br />
                        <strong>{data.additionalContactInfo.addressLabel}</strong> {data.additionalContactInfo.address}<br />
                        <strong>{data.additionalContactInfo.emailLabel}</strong> <a href={`mailto:${data.additionalContactInfo.email}`} className="text-blue-600">{data.additionalContactInfo.email}</a><br />
                        <strong>{data.additionalContactInfo.phoneLabel}</strong> <a href={`tel:${data.additionalContactInfo.phone}`} className="text-blue-600">{data.additionalContactInfo.phone}</a><br />
                        <strong>{data.additionalContactInfo.representativeLabel}</strong> {data.additionalContactInfo.representative}<br />
                        <strong>{data.additionalContactInfo.taxIdLabel}</strong> {data.additionalContactInfo.taxId} */}
                    {/* </address>
                </section> */}
            </div>
        </div>
    );
};

export default DerechoUsuario;
