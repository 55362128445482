import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './routes/AppRoutes';
import { AppProvider } from './context/AppProvider';
import React, { useEffect } from 'react';

function App() {
//   useEffect(() => {
//     const setFavicon = () => {
//       const darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
//       const favicon = document.querySelector("link[rel='icon']");
      
//       if (favicon) {
//         favicon.href = darkMode ? `${process.env.PUBLIC_URL}/favicon.ico` : `${process.env.PUBLIC_URL}/favicon3.ico`;
//       }
//     };

//     // Llama a la función para establecer el favicon inicial
//     setFavicon();

//     // Escucha los cambios en el esquema de color
//     const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
//     darkModeMediaQuery.addEventListener("change", setFavicon);

//     // Limpia el evento cuando el componente se desmonte
//     return () => darkModeMediaQuery.removeEventListener("change", setFavicon);
//   }, []);

  return (
    <>
        <AppProvider>
            <AppRoutes />
        </AppProvider>
    </>
  );
}

export default App;
