const optimizedImageURL = (url) => {
    const cloudinaryBaseURL = 'https://res.cloudinary.com/dievolijo/image/upload/';
    return `${cloudinaryBaseURL}c_scale,w_800/${url}`;
};

const en = {
    menu_principal:[
        { 
            id: 0, 
            path: "/quienes-somos", 
            label: "¿Quiénes Somos?", 
            subTema: "Innovacorp es líder en el desarrollo de software y soluciones innovadoras.",
        },
        { 
            id: 1, 
            path: "/nuestros-servicios/#", 
            label: "Nuestros Servicios", 
            subTema: "Nuestros servicios están diseñados para impulsar la innovación y la eficiencia.", 
            subItems: [
                { path: "/servicios/desarrollo-software", label: "Desarrollo de Software a Medida" },
                { path: "/servicios/consultoria-tecnologica", label: "Consultoría de Innovación Tecnológica" },
                { path: "/servicios/transformacion-digital", label: "Transformación Digital" },
                { path: "/servicios/seguridad", label: "Seguridad Gubernamental y Corporativa" }
            ]
        },
        { 
            id: 3, 
            path: "/nuestros-clientes", 
            label: "Nuestros Clientes", 
            subTema: "Trabajamos con una amplia gama de clientes, desde startups hasta agencias gubernamentales."
        },
        { 
            id: 2, 
            path: "/proyectos-destacados", 
            label: "Proyectos Destacados",
            subTema: "Descubre algunos de nuestros proyectos destacados.", 
            subItems: [
                { path: "/proyectos/mappi", label: "Mappi.pe" },
                { path: "/proyectos/logistica", label: "Empresa Privada de Logística" }
            ]
        },
        { 
            id: 4, 
            path: "/por-que-elegir-innovacorp", 
            label: "¿Por qué elegir innovacorp?", 
            subTema: "Nos destacamos por nuestro talento excepcional, enfoque en resultados y compromiso con la innovación."
        },
        { 
            id: 5, 
            path: "/contactanos", 
            label: "Contáctanos", 
            subTema: "Contáctanos hoy para dar el siguiente paso en tu transformación tecnológica."
        }
    ],    
    home: {
        text1: "¿QUIERES",
        texts: ["TRANSFORMAR IDEAS EN REALIDADES DIGITALES?", "SOLUCIONES TECNOLÓGICAS A MEDIDA?", "UN CAMBIO HACIA LA DIGITALIZACIÓN?", "INNOVAR Y MEJORAR TU NEGOCIO?"],
        itemServices: [
            { title: "Desarrollo de Software a Medida", description: "Creamos aplicaciones personalizadas que se adaptan perfectamente a las necesidades únicas de tu negocio.", path: "/servicios/desarrollo-software" },
            { title: "Consultoría de Innovación Tecnológica", description: "Investigamos y descubrimos oportunidades y optimizamos procesos organizacionales mediante la adopción de tecnologías avanzadas.",path: "/servicios/consultoria-tecnologica"},
            { title: "Transformación Digital", description: "Acompañamos a las empresas en su viaje hacia la digitalización, mejorando la eficiencia y reduciendo costos operativos.", path: "/servicios/transformacion-digital" },
            { title: "Innovación Seguridad Gubernamental y Corporativa", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", path: "/servicios/seguridad" },
        ],
        detail1: "En Innovacorp, transformamos ideas en realidades digitales. Somos un equipo multidisciplinario dedicados a crear soluciones para problemas.",
        detail2: "Hoy en día, no se trata solo de implementar tecnología, sino de crear soluciones personalizadas que impulsen la digitalización y optimicen las operaciones de las empresas para alcanzar sus objetivos estratégicos.",
        detail3: { path: "/contactanos", label: "Contáctanos", },
        homeInfo1: "LA SOCIEDAD CAMBIA, LAS TECNOLOGÍAS AVANZAN, ¿POR QUÉ NO LLEVAR TU NEGOCIO AL SIGUIENTE NIVEL CON SOLUCIONES DIGITALES INNOVADORAS?",
        infoItems2: [
            { 
                title: "¿QUIÉNES SOMOS?", 
                description: "Innovacorp es una empresa de desarrollo de software con más de 5 años de experiencia, creando soluciones que impactan positivamente en empresas y organizaciones.", 
                image: optimizedImageURL('v1731116016/f8qrdtnzoiidxcr73jjh.webp') 
            },
            { 
                title: "NUESTROS SERVICIOS", 
                description: "Ofrecemos desarrollo de software a medida, consultoría en tecnología, transformación digital y seguridad para empresas y gobiernos.", 
                image: optimizedImageURL('v1731116016/vky5hgv5ucqggshg8s7c.webp') 
            },
            { 
                title: "NUESTROS CLIENTES", 
                description: "Trabajamos con startups y entidades gubernamentales, comprometiéndonos con la calidad y superando expectativas en cada proyecto.", 
                image: optimizedImageURL('v1731116016/y4axynb7nsey8fdv8cef.webp') 
            },
            { 
                title: "PROYECTOS DESTACADOS", 
                description: "Hemos implementado proyectos como Mappi.pe y una plataforma de logística, optimizando operaciones y tiempos de entrega.", 
                image: optimizedImageURL('v1731116210/r823p2x9wdiidyitsian.jpg') 
            },
            { 
                title: "¿POR QUÉ ELEGIR INNOVACORP?", 
                description: "Contamos con talento excepcional y un enfoque en resultados para superar los desafíos tecnológicos de cada cliente.", 
                image: optimizedImageURL('v1731116284/b1j6ptkwo2mtykwt3dnv.jpg') 
            },
        ],     
        tituloServicio: "Nuestras Ventajas Competitivas:",
        services: [
            { title: "TALENTO EXCEPCIONAL", image: optimizedImageURL('v1731117279/rh72jieeqosstdieiikw.webp') },
            { title: "ENFOQUE EN RESULTADOS", image: optimizedImageURL('v1731117321/sx0ettibqmdzo6uhz3yx.webp') },
            { title: "COMPROMISO CON LA INNOVACIÓN", image: optimizedImageURL('v1731117344/qbrytsby1ovp7q9xulhg.webp') },
        ],
        homeInfo2: "¿DESEAS MÁS INFORMACIÓN?",
        homeInfo3: { path: "/contactanos", label: "Contáctanos"}
    },
    footer:{
        Informationen:{title:"Información",links:[
            {link:"/derechos-del-usuario",detail:"Derechos del Usuario"},
            {link:"/politica-de-privacidad",detail:"Política de Privacidad"},
        ]},
        MeineDienstleistungen:{title:"Nuestros Servicios",links:[
            {link:"/servicios/desarrollo-software",detail:"Desarrollo de Software a Medida"},
            {link:"/servicios/consultoria-tecnologica",detail:"Consultoría de Innovación Tecnológica"},
            {link:"/servicios/transformacion-digital",detail:"Transformación Digital"},
            {link:"/servicios/seguridad",detail:"Seguridad Gubernamental y Corporativa"},
        ]},
        Kontakt:{title:"Contacto",links:[
            {detail:"Correo: contacto@innovacorp.tech"},
            {detail:"Teléfono: 2541424"},
            {detail:"Dirección: direccion01"},
            {detail:"Horario: Lun-Vie, 9:00 - 18:00"},
        ]},
        SocialMedia: {
            title: "Síguenos",
            links: [
                { link: "https://facebook.com", detail: "Facebook" },
                { link: "https://twitter.com", detail: "Twitter" },
                { link: "https://linkedin.com", detail: "LinkedIn" }
            ]
        },
        info:"2024 Todos los derechos reservados"
    },
    QuienesSomos:{
        info1:{
            title:"Quiénes Somos",
            description:"Nuestra historia comienza en el año 2014, cuando Sandra Roggero Manrique, nacida en Perú, altamente especializada en Recursos Humanos y Reclutamiento de Personal en Lima, Perú y experta en Relaciones Públicas, emigró a Alemania. Quince años antes, había viajado por Europa, donde evaluó y analizó las necesidades del mercado laboral de su país de origen y la mano de obra altamente calificada existente, con la visión de que también se necesitarían en Alemania, donde finalmente se establecería.",
            description2:"Somos diferentes",
            description3:"En primer lugar, porque hacemos preguntas. Exigimos. Nos involucramos. Asesoramos. Somos honestos y no andamos con rodeos. Invertimos nuestro tiempo y dedicación. Estamos comprometidos con corazón y pasión.",
        },
        homeInfo2:"¿DESEA MÁS INFORMACIÓN?",
        homeInfo3:{ path: "/contactanos", label: "Contáctanos"}
    },
    NuestroServicio:{
        DesarrolloSoftware:{
            title:"Desarrollo de Software a Medida",
            description:"Creamos aplicaciones personalizadas que se adaptan perfectamente a las necesidades únicas de tu negocio.",
        },
        ConsultoriaInnovaciónTecnológica:{
            title:"Consultoría de Innovación Tecnológica",
            description:"Investigamos y descubrimos oportunidades y optimizamos procesos organizacionales mediante la adopción de tecnologías avanzadas.",
        },
        TransformacionDigital:{
            title:"Transformación Digital",
            description:"Acompañamos a las empresas en su viaje hacia la digitalización, mejorando la eficiencia y reduciendo costos operativos.",
        },
        InnovacionSeguridad:{
            title:"Innovación Seguridad Gubernamental y Corporativa",
            description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        homeInfo2:"¿DESEA MÁS INFORMACIÓN?",
        homeInfo3:{ path: "/contactanos", label: "Contáctanos"}
    },
    NuestrosClientes:{
        info1:{
            title:"Nuestros Clientes",
            description:"Desde startups dinámicas hasta agencias gubernamentales, nuestros clientes cuentan con nuestro compromiso con la calidad, creatividad e integridad. Cada proyecto es una oportunidad para innovar y superar expectativas.",
        },
        homeInfo2:"¿DESEA MÁS INFORMACIÓN?",
        homeInfo3:{ path: "/contactanos", label: "Contáctanos"}
    },
    Proyectos: {
        Mappi: {
            title: "Mappi",
            description: "Proyecto de digitalización y educación para emprendedores gastronómicos en Lima Metropolitana, promoviendo una interacción más eficiente entre el gobierno local y los clientes potenciales. Este proyecto también incluye capacitación constante y supervisión por parte del gobierno municipal, impulsando el crecimiento y la sostenibilidad de los negocios locales.",
            features: [
                "Interacción digital entre emprendedores y gobierno local.",
                "Capacitación continua para los emprendedores.",
                "Supervisión y soporte de entidades municipales.",
                "Optimización de la experiencia del usuario para clientes potenciales."
            ],
            achievements: [
                "Más de 1000 emprendedores capacitados.",
                "Implementación de un sistema de supervisión automatizado.",
                "Reconocimiento a nivel regional como modelo de digitalización."
            ],
            websiteLink: "https://mappi.pe"
        },
        EmpresaPrivadaLogistica: {
            title: "Empresa Privada Logística",
            description: "Implementación de una plataforma de optimización de rutas que redujo el tiempo de entrega en un 25%.",
        },
        homeInfo2: "¿DESEA MÁS INFORMACIÓN?",
        homeInfo3: { path: "/contactanos", label: "Contáctanos" }
    },    
    PorqueElegirInnovacorp:{
        tittle:"¿Por qué elegir innovacorp?",
        TalentoExcepcional:{
            tittle:"Talento Excepcional: ",
            description:"Un equipo multidisciplinario que reúne creatividad y experticia técnica."
        },
        EnfoqueResultado:{
            tittle:"Enfoque en Resultados: ",
            description:"Nos enfocamos en superar los desafíos tecnológicos y de negocio específicos de cada cliente."
        },
        CompromisoInnovacion:{
            tittle:"Compromiso con la Innovación: ",
            description:"La curiosidad impulsa nuestro trabajo; siempre estamos buscando nuevas formas de mejorar y reinventar."
        },
        homeInfo2:"¿DESEA MÁS INFORMACIÓN?",
        homeInfo3:{ path: "/contactanos", label: "Contáctanos"}
    },
    Contactanos:{
        title:"Contáctanos",
        description:"¿Listo para dar el siguiente paso en tu transformación tecnológica? Contáctanos hoy para discutir cómo Innovacorp puede ayudarte a lograr tus objetivos.",
        option1:"SOY UNA EMPRESA",
        option2:"SOY UN SOLICITANTE",
        nombre:"Nombre",
        apellido:"Apellido",
        telefono:"Teléfono",
        empresa:"Empresa",
        stadt:"Ciudad",
        position:"Posición",
        email:"Correo electrónico",
        mensaje:"Mensaje (opcional)",
        mensaje2:"mensaje",
        archivo1:"Arrastre su currículum aquí o haga clic para seleccionar un archivo",
        archivo2:" (Solo archivos PDF o Word)",
        firma1:"Acepto la ",
        firma2:" política de privacidad",
        firma2Link:"/politica-de-privacidad",
        firma3:"aceptoPolíticaDePrivacidad",
        firma4:"Acepto los ",
        firma5:"Derechos de Usuario",
        firma5Link:"/derechos-del-usuario",
        firma6:"Acepto los",
        firma7:"Derechos de Usuario",
        senden:"Enviar"
    },
    PoliticaPrivacidad: {
        title: "Política de Privacidad",
        description1: "En Innovacorp, nos comprometemos a proteger la privacidad y los datos personales de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos su información cuando utiliza nuestros servicios.",

        infos1: {
            title: "Información que Recopilamos",
            info1: "Datos personales proporcionados voluntariamente, como nombre, correo electrónico y teléfono.",
            info2: "Información de uso, como las páginas visitadas y la duración de la visita.",
            info3: "Datos técnicos, incluyendo dirección IP, tipo de navegador y dispositivo.",
            info4: "Cookies y tecnologías de seguimiento para mejorar su experiencia en nuestro sitio web.",
        },

        infos2: {
            title: "Uso de la Información",
            description1: "Utilizamos la información recopilada para diversos fines, incluyendo:",
            info1: "Mejorar nuestros servicios y desarrollar nuevas funciones.",
            info2: "Proporcionar soporte al cliente y responder a consultas.",
        },

        infos3: {
            title: "Protección de Datos",
            description1: "En Innovacorp, implementamos medidas de seguridad avanzadas para proteger sus datos personales contra accesos no autorizados, alteración o divulgación indebida. Sin embargo, ningún método de transmisión por Internet o almacenamiento electrónico es completamente seguro.",
        }
    },
    DerechoUsuario: {
        title: "Derechos del Usuario",
        description1: "Aquí encontrarás toda la información relevante sobre tus derechos como usuario...",
        companyName: "[Nombre de tu empresa]",
        companyAddress: "[Dirección de tu empresa]",
        email: "[Correo electrónico de tu empresa]",
        phone: "[Teléfono de tu empresa]",
        infos: {
            info1: "Nombre de la Empresa:",
            info2: "Dirección:",
            info3: "Correo Electrónico:",
            info4: "Teléfono:",
        },
        description2: "Tienes derecho a acceder, rectificar y eliminar tus datos personales, así como a solicitar su limitación y portabilidad, entre otros.",
        
        topics: [
            {
                title: "Acceso a tus Datos",
                description: "Tienes derecho a saber qué datos personales tenemos sobre ti y cómo los utilizamos.",
            },
            {
                title: "Rectificación de tus Datos",
                description: "Si alguno de tus datos es incorrecto, puedes solicitar su corrección.",
            },
            // Agrega más temas según sea necesario
        ],
        
        additionalContactInfo: {
            title: "Información de Contacto Adicional",
            companyLabel: "Nombre de la Empresa:",
            companyName: "Sandra Roggero M. Beratung",
            addressLabel: "Dirección:",
            address: "[Dirección de la empresa]",
            emailLabel: "Correo Electrónico de Contacto:",
            email: "[Correo de contacto]",
            phoneLabel: "Teléfono de Contacto:",
            phone: "[Número de teléfono]",
            representativeLabel: "Representante Legal:",
            representative: "[Nombre del representante]",
            taxIdLabel: "Número de Identificación Fiscal:",
            taxId: "[Número de identificación fiscal]",
        }
    }
}


export default en;
