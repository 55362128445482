import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { FaSpinner } from 'react-icons/fa';
import HeaderNav from "../pages/assets/HeaderNav";
import Footer from "../pages/assets/Footer";
import { useAppState } from "../hooks/useAppState";
import useVideoPreloading from "../hooks/useVideosPreLoader";
import QuienesSomos from "../components/Quienes-Somos/QuienesSomos";
import DesarrolloSoftware from "../components/Nuestros-Servicios/DesarrolloSoftware";
import ConsultoriaTecnologica from "../components/Nuestros-Servicios/ConsultoriaTecnologica";
import TransformacionDigital from "../components/Nuestros-Servicios/TransformacionDigital";
import InnovacionSeguridad from "../components/Nuestros-Servicios/InnovacionSeguridad";
import NuestrosClientes from "../components/Nuestros-Clientes/NuestrosClientes";
import Mappi from "../components/Proyectos/mappi";
import EmpresaPrivadaLogistica from "../components/Proyectos/EmpresaPrivadaLogistica";
import PorqueElegirInnovacorp from "../components/PorqueElegirInnovacorp/PorqueElegirInnovacorp";
import Contacto from "../components/Contacto/Contacto";
import PoliticaPrivacidad from "../pages/assets/PoliticaPrivacidad";
import DerechoUsuario from "../pages/assets/DerechoDelUsuario";

// Importa los componentes de las páginas
const HomePage = lazy(() => import("../pages/home/HomePage"));

const PrivacyPolicy = lazy(() => import("../pages/assets/PrivacyPolicy"));
const Benutzerrechte = lazy(() => import("../pages/assets/Benutzerrechte"));

export const AppRoutes = () => {
  const { activeCarga, tipoIdioma } = useAppState();
  // Cargamos las imagenes
  useVideoPreloading();

  return (
    <>
      <Router>
        <div>
            <>
              <HeaderNav />
              <Suspense
                fallback={
                  <div className="flex items-center justify-center h-screen bg-gradient-to-br from-gray-900 to-gray-700">
                    <FaSpinner className="animate-spin text-9xl text-gray-500" />
                  </div>
                }
              >
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  {
                    tipoIdioma === 'es' && (
                      <>
                        <Route path="/quienes-somos" element={<QuienesSomos />} />
                        <Route path="/servicios/desarrollo-software" element={<DesarrolloSoftware />} />
                        <Route path="/servicios/consultoria-tecnologica" element={<ConsultoriaTecnologica />} />
                        <Route path="/servicios/transformacion-digital" element={<TransformacionDigital />} />
                        <Route path="/servicios/seguridad" element={<InnovacionSeguridad />} />
                        {/* <Route path="/nuestros-clientes" element={<NuestrosClientes />} /> */}
                        <Route path="/proyectos/mappi" element={<Mappi />} />
                        <Route path="/proyectos/logistica" element={<EmpresaPrivadaLogistica />} />
                        {/* <Route path="/por-que-elegir-innovacorp" element={<PorqueElegirInnovacorp />} /> */}
                        <Route path="/contactanos" element={<Contacto />} />

                        <Route path="/politica-de-privacidad" element={<PoliticaPrivacidad />} />
                        <Route path="/derechos-del-usuario" element={<DerechoUsuario />} />
                      </>
                    )
                  }
                  
                  {/* {tipoIdioma === 'de' ? (
                    <>
                      <Route path="/unternehmen/personalauswahl-und-rekrutierung" element={<PersonalauswahlUndRekrutierung />} />
                      <Route path="/unternehmen/personalbewertungen" element={<Personalbewertungen />} />
                      <Route path="/unternehmen/international" element={<International />} />
                      <Route path="/unternehmen/funktionen" element={<Funktionen />} />
                      <Route path="/unternehmen/fachspezifische-suche" element={<FachspezifischeSuche />} />
                      <Route path="/unternehmen/inklusionsaudit" element={<Inklusionsaudit />} />
                      <Route path="/kandidaten/registrieren-sie-ihren-lebenslauf" element={<RegistrierenSieIhrenLebenslauf />} />
                      <Route path="/kandidaten/internationales-karrieremanagement" element={<InternationalesKarrieremanagement />} />
                      <Route path="/wir/unsere-philosophie" element={<UnserePhilosophie />} />
                      <Route path="/wir/wer-wir-sind" element={<WerWirSind />} />
                      <Route path="/sonstige-dienstleistungen/studium-ausbildung-praktikum" element={<StudiumAusbildungPraktikum />} />
                      <Route path="/sonstige-dienstleistungen/arbeit-steuern" element={<ArbeitSteuern />} />
                      <Route path="/sonstige-dienstleistungen/wohnen-transportmittel" element={<WohnenTransport />} />
                      <Route path="/sonstige-dienstleistungen/visum-sprachkompetenzen" element={<VisumSprache />} />
                      <Route path="/kontakt" element={<Kontakt />} />
                      <Route path="/datenschutzrichtlinie" element={<PrivacyPolicy />} />
                      <Route path="/benutzerrechte" element={<Benutzerrechte />} />
                    </>
                  ) : (
                    tipoIdioma === 'en'? (
                      <>
                        <Route path="/company/personnel-selection-and-recruitment" element={<PersonalauswahlUndRekrutierung />} />
                        <Route path="/company/personnel-evaluations" element={<Personalbewertungen />} />
                        <Route path="/company/international" element={<International />} />
                        <Route path="/company/functions" element={<Funktionen />} />
                        <Route path="/company/specialized-search" element={<FachspezifischeSuche />} />
                        <Route path="/company/inclusion-audit" element={<Inklusionsaudit />} />
                        <Route path="/candidates/register-your-resume" element={<RegistrierenSieIhrenLebenslauf />} />
                        <Route path="/candidates/international-career-management" element={<InternationalesKarrieremanagement />} />
                        <Route path="/about-us/our-philosophy" element={<UnserePhilosophie />} />
                        <Route path="/about-us/who-we-are" element={<WerWirSind />} />
                        <Route path="/other-services/study-training-internships" element={<StudiumAusbildungPraktikum />} />
                        <Route path="/other-services/work-taxes" element={<ArbeitSteuern />} />
                        <Route path="/other-services/housing-transport" element={<WohnenTransport />} />
                        <Route path="/other-services/visa-language" element={<VisumSprache />} />
                        <Route path="/contact" element={<Kontakt />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/user-rights" element={<Benutzerrechte />} />
                      </>

                    ):(
                      <>
                      <Route path="/empresa/seleccion-y-reclutamiento-de-personal" element={<PersonalauswahlUndRekrutierung />} />
                      <Route path="/empresa/evaluaciones-de-personal" element={<Personalbewertungen />} />
                      <Route path="/empresa/internacional" element={<International />} />
                      <Route path="/empresa/funciones" element={<Funktionen />} />
                      <Route path="/empresa/busqueda-especializada" element={<FachspezifischeSuche />} />
                      <Route path="/empresa/auditoria-de-inclusion" element={<Inklusionsaudit />} />
                      <Route path="/candidatos/registre-su-curriculum" element={<RegistrierenSieIhrenLebenslauf />} />
                      <Route path="/candidatos/gestion-de-carrera-internacional" element={<InternationalesKarrieremanagement />} />
                      <Route path="/nosotros/nuestra-filosofia" element={<UnserePhilosophie />} />
                      <Route path="/nosotros/quienes-somos" element={<WerWirSind />} />
                      <Route path="/otros-servicios/estudio-formacion-practicas" element={<StudiumAusbildungPraktikum />} />
                      <Route path="/otros-servicios/trabajo-impuestos" element={<ArbeitSteuern />} />
                      <Route path="/otros-servicios/vivienda-transporte" element={<WohnenTransport />} />
                      <Route path="/otros-servicios/visa-idioma" element={<VisumSprache />} />
                      <Route path="/contacto" element={<Kontakt />} />
                      <Route path="/politica-de-privacidad" element={<PrivacyPolicy />} />
                      <Route path="/derechos-del-usuario" element={<Benutzerrechte />} />
                    </>
                    )
                  )} */}
                  
                  {/* Redirigir rutas no coincidentes a la página de inicio */}
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Suspense>
              <Footer />
            </>
          {/* )} */}
        </div>
      </Router>
    </>
  );
};

export default AppRoutes;
