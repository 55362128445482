import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppState } from "../../hooks/useAppState";
import de from "../../languaje/de";
import es from "../../languaje/es";
import en from "../../languaje/en";
import MeineDienstleistungen from "../../pages/assets/MeineDienstleistungen";
import WebMappi from "../../pages/assets/WebMappi";
import { AiOutlineClose } from "react-icons/ai";

const Mappi = () => {
    const images = ["v1731193903/v6xkah4rfqfjdvixvo8u.jpg"];
    const navigate = useNavigate();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const handleClickHome = () => {
        navigate("/");
    };

    const optimizedImageURL = (url) => {
        const cloudinaryBaseURL = 'https://res.cloudinary.com/dj1rac5ah/image/upload/';
        return `${cloudinaryBaseURL}c_scale,w_2000/${url}`;
    };

    const [data, setData] = useState(de.Proyectos);
    const { tipoIdioma } = useAppState();

    useEffect(() => {
        if (tipoIdioma === 'de') {
            setData(de.Proyectos);
        } else if (tipoIdioma === 'en') {
            setData(en.Proyectos);
        } else {
            setData(es.Proyectos);
        }
    }, [tipoIdioma]);

    const [imageIndexes, setImageIndexes] = useState([]);

    useEffect(() => {
        setImageIndexes(
            data.Mappi.image?.map(() => 0) || []
        );
    }, [data]);

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <>
            <div className="w-full h-screen bg-bg_favorite_1 relative">
                <img
                    src={optimizedImageURL(images[currentImageIndex])}
                    alt="NOT FOUND"
                    className="absolute top-0 left-0 w-full h-full object-cover z-0 fade-in-out"
                    loading="lazy"
                />
                <div className="bg-bg_favorite_1 flex flex-col justify-center items-center md:items-end h-full z-20 relative space-y-4 p-4 pt-32 sm:pt-48 md:pt-64 sm:p-6 md:p-8">
                    <div className="w-full md:w-3/5 h-auto">
                        <div className="text-white font-bold text-xl sm:text-4xl md:text-5xl font-montserrat text-center md:text-end px-2 flex justify-end items-end">
                            {/* v1731520158/yyifsjigxe85yhsxlbuf.png */}
                            {/* v1731520259/rakmjliqgf2vqxa6fktw.png */}
                            {/* {data.Mappi.title} */}
                            <img
                                src={optimizedImageURL("v1731520259/rakmjliqgf2vqxa6fktw.png")}
                                alt="NOT FOUND"
                                className=" w-72"
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-10">
                        {images.map((_, index) => (
                            <div
                                key={index}
                                className={`w-3 h-3 rounded-full ${index === currentImageIndex ? 'bg-white' : 'bg-gray-400'} cursor-pointer`}
                                onClick={() => handleImageClick(index)}
                            ></div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="w-11/12 px-2 sm:px-10 pb-10 mx-auto">
                <p className="text-base text-gray-400 py-10 font-montserrat">
                    <span onClick={handleClickHome} className="hover:cursor-pointer text-gray-800 font-medium">
                        Innova <span className="text-gray-500 font-medium text-base">Corp</span>
                    </span>
                    {' > '}
                    {data.Mappi.title}
                </p>
                <div className="text-gray-700 text-start w-full space-y-4">
                    <p>{data.Mappi.description}</p>
                    <div className="mt-6">
                        <a
                            href={data.Mappi.websiteLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 underline font-semibold hover:text-blue-800"
                        >
                            Visita el sitio web de Mappi
                        </a>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {data.Mappi.image?.map((product, index) => (
                        <div
                            key={index}
                            className="bg-white p-2 shadow-md rounded-lg transform transition-transform hover:scale-105 relative overflow-hidden"
                        >
                            <div
                                className="overflow-hidden rounded-md relative group"
                            >
                                <img
                                    src={optimizedImageURL(product)}
                                    alt={product.title}
                                    className="w-full h-full object-cover cursor-pointer"
                                    onClick={() => handleImageClick(optimizedImageURL(product))}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {selectedImage && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
                    <div className="relative">
                        <img
                            src={selectedImage}
                            alt="Imagen seleccionada"
                            className="max-w-full max-h-screen object-contain rounded-lg"
                        />
                        <button
                            className="absolute top-2 right-2 border-gray-800 rounded-full px-2 py-2 sm:px-2 sm:py-2 font-medium bg-gray-800 text-white hover:bg-gray-200 hover:text-gray-800 hover:border-gray-500 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50"
                            onClick={closeModal}
                        >
                            <AiOutlineClose size={16} />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Mappi;
