const optimizedImageURL = (url) => {
    const cloudinaryBaseURL = 'https://res.cloudinary.com/dj1rac5ah/image/upload/';
    return `${cloudinaryBaseURL}c_scale,w_800/${url}`;
};

const es = {
    menu_principal:[
        { 
            id: 0, 
            path: "/quienes-somos", 
            label: "¿Quiénes Somos?", 
            subTema: "Innovacorp es líder en el desarrollo de software y soluciones innovadoras.",
        },
        { 
            id: 1, 
            path: "/nuestros-servicios/#", 
            label: "Nuestros Servicios", 
            subTema: "Nuestros servicios están diseñados para impulsar la innovación y la eficiencia.", 
            subItems: [
                { path: "/servicios/desarrollo-software", label: "Desarrollo de Software y Aplicaciones" },
                { path: "/servicios/consultoria-tecnologica", label: "Consultoría de Innovación Tecnológica" },
                { path: "/servicios/transformacion-digital", label: "Transformación Digital" },
                { path: "/servicios/seguridad", label: "Seguridad Gubernamental y Corporativa" }
            ]
        },
        // { 
        //     id: 3, 
        //     path: "/nuestros-clientes", 
        //     label: "Nuestros Clientes", 
        //     subTema: "Trabajamos con una amplia gama de clientes, desde startups hasta agencias gubernamentales."
        // },
        { 
            id: 2, 
            path: "/proyectos-destacados", 
            label: "Proyectos Destacados",
            subTema: "Descubre algunos de nuestros proyectos destacados.", 
            subItems: [
                { path: "/proyectos/mappi", label: "Mappi.pe" },
                // { path: "/proyectos/logistica", label: "Empresa Privada de Logística" }
            ]
        },
        // { 
        //     id: 4, 
        //     path: "/por-que-elegir-innovacorp", 
        //     label: "¿Por qué elegir innovacorp?", 
        //     subTema: "Nos destacamos por nuestro talento excepcional, enfoque en resultados y compromiso con la innovación."
        // },
        { 
            id: 3, 
            path: "/contactanos", 
            label: "Contáctanos", 
            subTema: "Contáctanos hoy para dar el siguiente paso en tu transformación tecnológica."
        }
    ],    
    home: {
        text1: "¿QUIERES",
        texts: ["TRANSFORMAR IDEAS EN REALIDADES DIGITALES?", "SOLUCIONES TECNOLÓGICAS A MEDIDA?", "UN CAMBIO HACIA LA DIGITALIZACIÓN?", "INNOVAR Y MEJORAR TU NEGOCIO?"],
        itemServices: [
            { title: "Desarrollo de Software y Aplicaciones", description: "Creamos aplicaciones personalizadas que se adaptan perfectamente a las necesidades únicas de tu negocio.", path: "/servicios/desarrollo-software" },
            { title: "Consultoría de Innovación Tecnológica", description: "Investigamos y descubrimos oportunidades y optimizamos procesos organizacionales mediante la adopción de tecnologías avanzadas.",path: "/servicios/consultoria-tecnologica"},
            { title: "Transformación Digital", description: "Acompañamos a las empresas en su viaje hacia la digitalización, mejorando la eficiencia y reduciendo costos operativos.", path: "/servicios/transformacion-digital" },
            { title: "Innovación en Seguridad Gubernamental y Corporativa", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", path: "/servicios/seguridad" },
        ],
        homeContent:{
            title: "Bienvenidos a Innova Corp: Líder en Transformación Digital y Soluciones Tecnológicas",
            introduction: [
                "En Innova Corp, convertimos la tecnología en un motor de éxito para tu negocio. Como expertos en Desarrollo de Software, Consultoría en Innovación y Transformación Digital, ayudamos a empresas de todas las industrias a adaptarse y optimizar sus operaciones en un mercado cambiante.",
                "Desde nuestra fundación, nos esforzamos por mantenernos a la vanguardia, ofreciendo soluciones personalizadas y enfocadas en calidad, seguridad y escalabilidad, anticipándonos a las demandas del futuro."
            ],
            uniquePointsTitle: "¿Qué nos hace diferentes?",
            uniquePoints: [
                {
                    title: "Expertise Técnico de Primer Nivel",
                    description: "Nuestro equipo de expertos domina tecnologías avanzadas como IA, big data, blockchain y nube, brindando soluciones robustas y seguras alineadas con las mejores prácticas."
                },
                {
                    title: "Enfoque en Innovación Estratégica",
                    description: "Usamos metodologías como design thinking y agile para reinventar procesos y maximizar el retorno de inversión en tu empresa."
                },
                {
                    title: "Transformación Digital Integral",
                    description: "Guiamos tu transición digital, optimizando infraestructura y cultura empresarial para prepararte para los retos futuros."
                }
            ],            
            closing:{
                title:"¿Listo para innovar?",
                description:"Descubre cómo Innova Corp puede ser el catalizador de tu transformación digital. Contáctanos hoy y empieza a transformar tu visión en realidad."
            },
            contactLink: { path: "/contactanos", label: "Contáctanos" }
        },
        detailTitle:"Bienvenidos a Innova Corp: Líder en Transformación Digital y Soluciones Tecnológicas",
        detail1: "En Innovacorp, transformamos ideas en realidades digitales. Somos un equipo multidisciplinario dedicados a crear soluciones para problemas.",
        detail2: "Hoy en día, no se trata solo de implementar tecnología, sino de crear soluciones personalizadas que impulsen la digitalización y optimicen las operaciones de las empresas para alcanzar sus objetivos estratégicos.",
        detail3: { path: "/contactanos", label: "Contáctanos", },
        homeInfo1: "LA SOCIEDAD CAMBIA, LAS TECNOLOGÍAS AVANZAN, ¿POR QUÉ NO LLEVAR TU NEGOCIO AL SIGUIENTE NIVEL CON SOLUCIONES DIGITALES INNOVADORAS?",
        infoItems2: [
            { 
                title: "¿QUIÉNES SOMOS?", 
                description: "Innovacorp es una empresa de desarrollo de software con más de 5 años de experiencia, creando soluciones que impactan positivamente en empresas y organizaciones.", 
                image: optimizedImageURL('v1731116016/f8qrdtnzoiidxcr73jjh.webp') 
            },
            { 
                title: "NUESTROS SERVICIOS", 
                description: "Ofrecemos desarrollo de software a medida, consultoría en tecnología, transformación digital y seguridad para empresas y gobiernos.", 
                image: optimizedImageURL('v1731116016/vky5hgv5ucqggshg8s7c.webp') 
            },
            // { 
            //     title: "NUESTROS CLIENTES", 
            //     description: "Trabajamos con startups y entidades gubernamentales, comprometiéndonos con la calidad y superando expectativas en cada proyecto.", 
            //     image: optimizedImageURL('v1731116016/y4axynb7nsey8fdv8cef.webp') 
            // },
            { 
                title: "PROYECTOS DESTACADOS", 
                description: "Hemos implementado proyectos como Mappi.pe y una plataforma de logística, optimizando operaciones y tiempos de entrega.", 
                image: optimizedImageURL('v1731116210/r823p2x9wdiidyitsian.jpg') 
            },
            { 
                title: "¿POR QUÉ ELEGIR INNOVACORP?", 
                description: "Contamos con talento excepcional y un enfoque en resultados para superar los desafíos tecnológicos de cada cliente.", 
                image: optimizedImageURL('v1731116284/b1j6ptkwo2mtykwt3dnv.jpg') 
            },
        ],     
        tituloServicio: "Nuestras Ventajas Competitivas:",
        services: [
            { title: "TALENTO EXCEPCIONAL", image: optimizedImageURL('v1731117279/rh72jieeqosstdieiikw.webp') },
            { title: "ENFOQUE EN RESULTADOS", image: optimizedImageURL('v1731117321/sx0ettibqmdzo6uhz3yx.webp') },
            { title: "COMPROMISO CON LA INNOVACIÓN", image: optimizedImageURL('v1731117344/qbrytsby1ovp7q9xulhg.webp') },
        ],
        homeInfo2: "¿DESEAS MÁS INFORMACIÓN?",
        homeInfo3: { path: "/contactanos", label: "Contáctanos"}
    },
    footer:{
        Informationen:{title:"Información",links:[
            {link:"/derechos-del-usuario",detail:"Derechos del Usuario"},
            {link:"/politica-de-privacidad",detail:"Política de Privacidad"},
        ]},
        MeineDienstleistungen:{title:"Nuestros Servicios",links:[
            {link:"/servicios/desarrollo-software",detail:"Desarrollo de Software y Aplicaciones"},
            {link:"/servicios/consultoria-tecnologica",detail:"Consultoría de Innovación Tecnológica"},
            {link:"/servicios/transformacion-digital",detail:"Transformación Digital"},
            {link:"/servicios/seguridad",detail:"Seguridad Gubernamental y Corporativa"},
        ]},
        Kontakt:{title:"Contacto",links:[
            {detail:"Correo: contacto@innovacorp.tech"},
            // {detail:"Teléfono: 2541424"},
            {detail:"Dirección: Calle bellavista 325 Miraflores Of 202"},
            {detail:"Horario: Lun-Vie, 9:00 - 18:00"},
        ]},
        SocialMedia: {
            title: "Síguenos",
            links: [
                { link: "https://facebook.com", detail: "Facebook" },
                { link: "https://twitter.com", detail: "Twitter" },
                { link: "https://linkedin.com", detail: "LinkedIn" }
            ]
        },
        info:"2024 Todos los derechos reservados"
    },
    QuienesSomos: {
        info1: {
            title: "¿Quiénes Somos?",
            description: "Innovacorp es líder en el desarrollo de software y soluciones innovadoras. Con más de 5 años de experiencia, colaboramos estrechamente con clientes privados y entidades gubernamentales para diseñar, desarrollar e implementar proyectos que impactan de manera positiva en sus operaciones y en la vida de las personas.",
        },
        team: {
            title: "Nuestro Equipo",
            members: [
                {
                    name: "CEO Jonathan Paz",
                    description: "Mg. en Marketing y Negocios Internacionales, Profesor con certificación internacional en Emprendimiento e Innovación por la Universidad de Salamanca, España, con más de 10 años de experiencia en proyectos de comunicación, innovación, investigación y desarrollo.",
                    image: "v1731482757/vjfecjgccdtheaxkcep0.jpg"
                },
                {
                    name: "Manuel Foco",
                    description: "Ingeniero de Software Senior, Full Stack, con 12 años de experiencia en desarrollo de sistemas y aplicaciones web y móvil. Especializado en .NET, Angular, JavaScript, React. Con experiencia en arquitecturas escalables y microservicios.",
                    image: "v1731469426/drl9zob5whkawwa8xldk.png"
                },
                {
                    name: "Carolina Destinobles",
                    description: "Ingeniera Empresarial y de Sistemas, especialista en calidad de software, con más de 10 años de experiencia en pruebas funcionales, no funcionales, de regresión y automatización, así como en metodologías ágiles y procesos.",
                    image: "v1731469426/aauywt7grkqbbwytalbf.png"
                },
                {
                    name: "Frank Bocangelino",
                    description: "Desarrollador Full Stack con 3 años de experiencia en desarrollo de sistemas y aplicaciones, especialista en Angular, Node.js, React.js, Next.js, Spring para Java y bibliotecas como Express.js, Redux, React-Hooks, Rest API, Spring Data JPA y MySQL.",
                    image: "v1731469426/ai4askzpdpzp8wgfcnu4.png"
                },
            ]
        },
        mission: {
            title: "Misión",
            description: "Nuestra misión es impulsar el crecimiento y la transformación digital de las empresas, desarrollando soluciones tecnológicas a medida que mejoran la eficiencia, fomentan la innovación y crean valor a largo plazo. Nos comprometemos a ofrecer servicios de alta calidad en desarrollo de software, consultoría en innovación y transformación digital, trabajando en estrecha colaboración con nuestros clientes para convertir sus objetivos estratégicos en realidades digitales. Nos mueve la pasión por la tecnología y el deseo de ver a nuestros clientes triunfar en un entorno de constante evolución."
        },
        vision: {
            title: "Visión",
            description: "Ser el socio tecnológico de referencia para empresas que buscan liderar en la era digital. Aspiramos a construir un futuro donde las organizaciones puedan aprovechar al máximo el potencial de la tecnología para transformar sus procesos, mejorar su competitividad y ofrecer experiencias excepcionales a sus clientes. Nos proyectamos como una empresa innovadora y ágil, reconocida por su capacidad de anticiparse a las tendencias tecnológicas y por brindar soluciones de alto impacto que contribuyan al desarrollo sostenible y al crecimiento digital global."
        },
        valueTitle:"Valores que Nos Impulsan",
        values: [
            {
                title: "Innovación Continua",
                description: "Fomentamos un entorno donde las ideas disruptivas y las tecnologías emergentes son el motor de nuestro crecimiento y el de nuestros clientes."
            },
            {
                title: "Excelencia Técnica",
                description: "Nos comprometemos con los más altos estándares de calidad en el desarrollo y la implementación de nuestras soluciones tecnológicas."
            },
            {
                title: "Orientación al Cliente",
                description: "Entendemos las necesidades únicas de cada cliente y trabajamos en estrecha colaboración para superar sus expectativas."
            },
            {
                title: "Agilidad y Adaptabilidad",
                description: "Adoptamos metodologías ágiles que nos permiten responder rápidamente a los cambios y desafíos del mercado."
            },
            {
                title: "Sostenibilidad",
                description: "Promovemos prácticas responsables que aseguren un impacto positivo tanto para nuestros clientes como para la sociedad y el medio ambiente."
            }
        ],
        porqueelegirnos:{
            title:"¿Por qué elegirnos?",
            descripcion1:"Nuestra pasión es llevar a nuestros clientes al éxito mediante tecnología de vanguardia y soluciones que no solo resuelvan problemas actuales, sino que preparen tu negocio para el futuro. Trabajamos en estrecha colaboración contigo, asegurando que cada proyecto refleje tus objetivos estratégicos y maximice tu retorno de inversión.",
            descripcion2:"Empieza hoy tu viaje hacia el éxito digital. Contáctanos y transforma la manera en que haces negocios."
        },
        homeInfo2: "¿DESEA MÁS INFORMACIÓN?",
        homeInfo3: { path: "/contactanos", label: "Contáctanos" }
    },    
    NuestroServicio:{
        DesarrolloSoftware:{
            title:"Desarrollo de Software y Aplicaciones",
            description:"Creamos aplicaciones personalizadas que se adaptan perfectamente a las necesidades únicas de tu negocio.",
            productosSeguritech: [
                {
                    description:"Construimos software que potencia cada aspecto de tu negocio. Nuestro enfoque se centra en crear aplicaciones altamente personalizadas y adaptables, desde sistemas empresariales hasta aplicaciones móviles, que no solo cumplen tus necesidades actuales, sino que también están diseñadas para crecer junto a tu empresa. Utilizamos metodologías ágiles, integración continua y desarrollo basado en pruebas para garantizar la entrega de un producto robusto, seguro y fácil de mantener. Ya sea que necesites automatizar procesos, mejorar la experiencia del cliente o lanzar una nueva aplicación, contamos con un equipo de desarrolladores especializados en las últimas tecnologías, como inteligencia artificial, blockchain, y soluciones en la nube (AWS, Azure, Google Cloud).",
                    
                    image:"v1731472109/padhbchnovu8h8fxzpww.png"
                },
                {
                    description:"Diseñamos e implementamos software y aplicaciones a medida, enfocadas en tus necesidades específicas. Desde aplicaciones móviles hasta sistemas empresariales complejos, nuestras soluciones garantizan alto rendimiento y escalabilidad.",
                    image:"v1731472108/nhkzbvlbzg6s17zvxtlt.png",
                    subTittle:"¿Qué obtienes con nuestro desarrollo de software?",
                    options:[
                        "Soluciones escalables y seguras que evolucionan con tu negocio.",
                        "Interfaces intuitivas que mejoran la experiencia del usuario y maximizan la retención de clientes.",
                        "Automatización de procesos clave que reduce costos y errores operativos.",
                        "Implementación de IA y Big Data para ayudarte a tomar decisiones basadas en datos."
                    ],
                }
            ],
        },
        ConsultoriaInnovaciónTecnológica:{
            title:"Consultoría de Innovación Tecnológica",
            description:"Investigamos y descubrimos oportunidades y optimizamos procesos organizacionales mediante la adopción de tecnologías avanzadas.",
            productosSeguritech: [
                {
                    description:"Nuestro equipo de consultores trabaja contigo para reimaginar tu negocio en la era digital. Nos especializamos en análisis estratégico y desarrollo de ideas innovadoras que marcan la diferencia en el mercado. Evaluamos tus desafíos actuales y tus objetivos a largo plazo para identificar nuevas oportunidades de crecimiento, optimización de procesos y diferenciación frente a la competencia. Implementamos técnicas avanzadas de design thinking, innovación abierta y estudios de mercado digital para diseñar estrategias únicas que se ajustan a tu industria y a tu visión de futuro.",
                    
                    image:"v1731472176/yvids5gbrh3kgjt3gxkr.png",

                },
                {
                    description:"Nuestro equipo de expertos trabaja contigo para identificar nuevas oportunidades de negocio y optimizar tus procesos. Analizamos tu industria y te brindamos estrategias innovadoras para diferenciarte de la competencia.",
                    image:"v1731472110/gvhia12cct1qe96skolb.png",
                    subTittle:"Nuestro enfoque en consultoría incluye:",
                    options:[
                        "Análisis de tendencias y tecnologías emergentes que pueden beneficiar a tu negocio.",
                        "Diseño de productos y servicios innovadores que añaden valor a tu oferta.",
                        "Automatización de procesos clave que reduce costos y errores operativos.",
                        "Implementación de IA y Big Data para ayudarte a tomar decisiones basadas en datos."
                    ],
                }
            ],
        },
        TransformacionDigital:{
            title:"Transformación Digital",
            description:"Acompañamos a las empresas en su viaje hacia la digitalización, mejorando la eficiencia y reduciendo costos operativos.",
            productosSeguritech: [
                {
                    description:"Lleva tu negocio al futuro con una transformación digital integral que mejora cada aspecto de tu empresa. Desde la digitalización de procesos hasta la adopción de tecnología avanzada, nuestro enfoque abarca desde la infraestructura hasta la cultura organizacional. Guiamos tu transición hacia herramientas digitales efectivas y aprovechamos metodologías ágiles para hacer que cada cambio sea rápido y eficiente. Con experiencia en tecnología de la nube, automatización, e inteligencia artificial, ayudamos a tu empresa a ser más ágil, más segura y preparada para competir en la economía digital.",
                    image:"v1731472178/y6iijidzrzly0olz2yj3.png",
                },
                {
                    description:"Facilitamos la modernización de tu empresa, integrando herramientas digitales avanzadas y metodologías ágiles que optimizan tus operaciones. Digitalizamos cada aspecto de tu negocio para que puedas alcanzar nuevos niveles de eficiencia y adaptarte rápidamente a los cambios del mercado.",
                    image:"v1731472178/m1a9dtlez93186itlyku.png",
                    subTittle:"Beneficios de nuestra transformación digital:",
                    options:[
                        "Infraestructura en la nube que asegura flexibilidad y reduce costos operativos.",
                        "Automatización y análisis de datos en tiempo real para mejorar la toma de decisiones.",
                        "Ciberseguridad avanzada que protege la información sensible de tu empresa y de tus clientes.",
                        "Integración de IA y machine learning para optimizar operaciones y personalizar la experiencia del cliente."
                    ],
                }
            ],
        },
        InnovacionSeguridad:{
            title:"Innovación en Seguridad Gubernamental y Corporativa",
            description:"Seguritech es nuestra linea de servicios de seguridad de vigilancia, selección de personal y desescalamiento de violencia. Tenemos una variedad de software con inteligencia artificial y productos innovadores que revolucionaran tus resultados de vigilancia y disuasión de objetivos en el campo de acción.",
            productosSeguritech: [
                {
                    title: "A-SYSTEM",
                    description: "El sistema analiza las vibraciones diminutas de las imágenes capturadas y cuantifica emociones como el estrés y la agresión. En base a la información obtenida del análisis de video, se crea automáticamente un informe de resultados del análisis. Puede ver el estado de la situación mental actual del objetivo estrés ansiedad e incluso problemas psiquiátricos.",
                    image: ["v1731437711/a9gvqknonckxbwk4b6cm.png"],
                },
                {
                    title: "Defender-X",
                    description: "Visualiza la frecuencia y amplitud de los micro movimientos con colores. Detecta personas en estados mentales anormales que tienen diferentes patrones de micro vibraciones y las considera personas sospechosas.",
                    image: ["v1731437758/oylh2xsfvfbzp1yhnafr.jpg","v1731437758/rt82nuymswbd2se1zbzw.jpg"],
                },
                {
                    title: "BOLA LAUNCHER",
                    description: "Es un dispositivo Propulsado por aire que lanza una cuerda de kevlar a alta velocidad y atrapa al objetivo para poder reducirlo, Libre de daños corporales. El dispositivo provoca una gran sonido para cumplir con un propósito intimidante, Es reutilizable y se puede volver a usar en pocos minutos, campo de acción hasta 10 metros de efectividad.",
                    image: ["v1731440769/yhc3kumxecupmv9s74eg.jpg","v1731440769/js6t097suo0gjjtjzwb7.jpg","v1731440769/dw87qitkfax8wyluohdj.png","v1731440769/dcmhtfjcghpdwlidol1i.jpg","v1731440770/a4wt5ctegsxa0hqiopc9.png","v1731440770/nu4uxrpoa7snlgmhbnrl.jpg"],
                },
                {
                    title: "Orochi SO7",
                    description: "Es compacto y puede usarse para sujetar instantáneamente a personas en situaciones de emergencia. Tamaño compacto y práctico, muy portátil y fácil de usar en situaciones de emergencia. Fácil de operar y restringe instantáneamente. Especializado para sujetar las muñecas, brazos y tobillos de personas violentas.",
                    image: ["v1731440628/zefuakiksmb0tuco3wpv.jpg","v1731440628/mubwxrcp0ilomnb0dlka.jpg"],
                }
            ],
        },
        homeInfo2:"¿DESEA MÁS INFORMACIÓN?",
        homeInfo3:{ path: "/contactanos", label: "Contáctanos"}
    },
    NuestrosClientes:{
        info1:{
            title:"Nuestros Clientes",
            description:"Desde startups dinámicas hasta agencias gubernamentales, nuestros clientes cuentan con nuestro compromiso con la calidad, creatividad e integridad. Cada proyecto es una oportunidad para innovar y superar expectativas.",
        },
        homeInfo2:"¿DESEA MÁS INFORMACIÓN?",
        homeInfo3:{ path: "/contactanos", label: "Contáctanos"}
    },
    Proyectos: {
        Mappi: {
            title: "Mappi",
            description: "Proyecto de digitalización y educación para emprendedores gastronómicos en Lima Metropolitana, promoviendo una interacción más eficiente entre el gobierno local y los clientes potenciales. Este proyecto también incluye capacitación constante y supervisión por parte del gobierno municipal, impulsando el crecimiento y la sostenibilidad de los negocios locales.",
            features: [
                "Interacción digital entre emprendedores y gobierno local.",
                "Capacitación continua para los emprendedores.",
                "Supervisión y soporte de entidades municipales.",
                "Optimización de la experiencia del usuario para clientes potenciales."
            ],
            achievements: [
                // "Más de 1000 emprendedores capacitados.",
                // "Implementación de un sistema de supervisión automatizado.",
                // "Reconocimiento a nivel regional como modelo de digitalización."
            ],
            image:["v1731460872/hrx4dqk78i7pvutuddvh.png","v1731460895/rigswxznxdlopraj0xla.png","v1731463546/hjf9uywjb20smuwpeq7v.png","v1731461377/a6wez33clastyld4tz3p.png","v1731461418/t1wnyu6kdwv7acdlkbyx.png"],
            websiteLink: "https://mappi.pe"
        },
        EmpresaPrivadaLogistica: {
            title: "Empresa Privada Logística",
            description: "Implementación de una plataforma de optimización de rutas que redujo el tiempo de entrega en un 25%.",
        },
        homeInfo2: "¿DESEA MÁS INFORMACIÓN?",
        homeInfo3: { path: "/contactanos", label: "Contáctanos" }
    },
    
    PorqueElegirInnovacorp:{
        tittle:"¿Por qué elegir innovacorp?",
        TalentoExcepcional:{
            tittle:"Talento Excepcional: ",
            description:"Un equipo multidisciplinario que reúne creatividad y experticia técnica."
        },
        EnfoqueResultado:{
            tittle:"Enfoque en Resultados: ",
            description:"Nos enfocamos en superar los desafíos tecnológicos y de negocio específicos de cada cliente."
        },
        CompromisoInnovacion:{
            tittle:"Compromiso con la Innovación: ",
            description:"La curiosidad impulsa nuestro trabajo; siempre estamos buscando nuevas formas de mejorar y reinventar."
        },
        homeInfo2:"¿DESEA MÁS INFORMACIÓN?",
        homeInfo3:{ path: "/contactanos", label: "Contáctanos"}
    },
    Contactanos: {
        title: "Contáctanos",
        description: "¿Listo para dar el siguiente paso en tu transformación tecnológica? Contáctanos hoy para discutir cómo Innovacorp puede ayudarte a lograr tus objetivos.",
        serviciosInteres: "Servicios de Interés",
        correo:"Correo",
        nombre:"Nombre",
        telefono:"Teléfono (Opcional)",
        mensaje:{
            label:"mensaje",
            descripcion:"Mensaje"
        },
        servicio:"Selecciona un servicio :",
        servicios: {
            opcion1: "Consultoría tecnológica",
            opcion2: "Desarrollo de software personalizado",
            opcion3: "Implementación de sistemas",
            opcion4: "Inteligencia artificial y machine learning",
        },
        enviar:"Enviar"
    },
    PoliticaPrivacidad: {
        title: "Política de Privacidad",
        description1: "En Innovacorp, nos comprometemos a proteger la privacidad y los datos personales de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos su información cuando utiliza nuestros servicios.",

        infos1: {
            title: "Información que Recopilamos",
            info1: "Datos personales proporcionados voluntariamente, como nombre, correo electrónico y teléfono.",
            info2: "Información de uso, como las páginas visitadas y la duración de la visita.",
            info3: "Datos técnicos, incluyendo dirección IP, tipo de navegador y dispositivo.",
            info4: "Cookies y tecnologías de seguimiento para mejorar su experiencia en nuestro sitio web.",
        },

        infos2: {
            title: "Uso de la Información",
            description1: "Utilizamos la información recopilada para diversos fines, incluyendo:",
            info1: "Mejorar nuestros servicios y desarrollar nuevas funciones.",
            info2: "Proporcionar soporte al cliente y responder a consultas.",
        },

        infos3: {
            title: "Protección de Datos",
            description1: "En Innovacorp, implementamos medidas de seguridad avanzadas para proteger sus datos personales contra accesos no autorizados, alteración o divulgación indebida. Sin embargo, ningún método de transmisión por Internet o almacenamiento electrónico es completamente seguro.",
        }
    },
    DerechoUsuario: {
        title: "Derechos del Usuario",
        description1: "Aquí encontrarás toda la información relevante sobre tus derechos como usuario...",
        companyName: "innova corp",
        companyAddress: "Calle bellavista 325 Miraflores Of 202",
        email: "contacto@innovacorp.tech",
        phone: "[Teléfono de tu empresa]",
        infos: {
            info1: "Nombre de la Empresa:",
            info2: "Dirección:",
            info3: "Correo Electrónico:",
            info4: "Teléfono:",
        },
        description2: "Tienes derecho a acceder, rectificar y eliminar tus datos personales, así como a solicitar su limitación y portabilidad, entre otros.",
        
        topics: [
            {
                title: "Acceso a tus Datos",
                description: "Tienes derecho a saber qué datos personales tenemos sobre ti y cómo los utilizamos.",
            },
            {
                title: "Rectificación de tus Datos",
                description: "Si alguno de tus datos es incorrecto, puedes solicitar su corrección.",
            },
            // Agrega más temas según sea necesario
        ],
        
        additionalContactInfo: {
            title: "Información de Contacto Adicional",
            companyLabel: "Nombre de la Empresa:",
            companyName: "Sandra Roggero M. Beratung",
            addressLabel: "Dirección:",
            address: "[Dirección de la empresa]",
            emailLabel: "Correo Electrónico de Contacto:",
            email: "[Correo de contacto]",
            phoneLabel: "Teléfono de Contacto:",
            phone: "[Número de teléfono]",
            representativeLabel: "Representante Legal:",
            representative: "[Nombre del representante]",
            taxIdLabel: "Número de Identificación Fiscal:",
            taxId: "[Número de identificación fiscal]",
        }
    }

}

export default es;
